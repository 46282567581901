@tailwind base;
@tailwind components;
@tailwind utilities;
/* Import slick-carousel styles */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

/* css background-image generated using https://10015.io/tools/css-background-pattern-generator */
.background {
  background-image: radial-gradient(#ffffff 2.5px, transparent 2.5px), radial-gradient(#ffffff 2.5px, transparent 2.5px);
  background-size: 36px 36px;
  background-position: 0 0, 18px 18px;
  background-color: #b1d2ff;
}


/* css loader generated using https://10015.io/tools/css-loader-generator */
.spinner {
   margin: 40px;
   width: 11.2px;
   height: 11.2px;
   animation: spinner-z355kx 1s infinite linear;
   border-radius: 11.2px;
   box-shadow: 28px 0px 0 0 #fff, 17.4px 21.8px 0 0 #fff, -6.2px 27.2px 0 0 #fff, -25.2px 12px 0 0 #fff, -25.2px -12px 0 0 #fff, -6.2px -27.2px 0 0 #fff, 17.4px -21.8px 0 0 #fff;
}

.react-tel-input .form-control {
   @apply !w-full py-6 !text-base;
 }
 
 .opt-container {
   @apply flex justify-between gap-2;
 }
 .opt-container input {
   @apply !mr-0 flex-1 py-5 outline-none;
 }
 

@keyframes spinner-z355kx {
   to {
      transform: rotate(360deg);
   }
}